@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.secessionlast{
  width: 100%;
  max-width: 499px;
  @include display(flex, column, center, start);
  .quidebox{
    @include display(flex, column, start, center);
    width: 90%;
    .explain{
      @include display(flex, row, center, start);
      margin-top: 1vh;
      font-family: 'Pretendard';
      .dot{
        background-color: black;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        margin: 0 2vh 0 0;
      }
    }
  }
  .buttonbox{
    @include display(flex, row, center, start);
    width: 90%;
  }
}