.videobyquarter{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 499px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5vh 0;
    width: 90%;
    .centerinfo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: -5vh;
      .fc{
        font-size: 2vh;
        font-weight: 600;
        font-family: 'Pretendard';
        color: #333333;
        margin: 0;
      }
      .date{
        font-size: 1.5vh;
        font-weight: 600;
        font-family: 'Pretendard';
        color: #AFAFAF;
        margin:1vh 0;
      }
    }
    .rightbuttons{
      display: flex;
      justify-content: center;
      align-items: center;
      .download{
        width: 3vh;
        height: 3vh;
        margin: 0 1.5vh;
      }
      .share{
        width: 3vh;
        height: 3vh;
      }
      
    }
  }
  .quarter-tabs {
    width: 100%;
    margin: 0 auto;
  
    .tabs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between;
      border-bottom: 1px solid #DADFE5;
  
      .tab {
        color: #C6C6C6;
        font-size: 2vh;
        font-weight: bold;
        font-family: 'Pretendard';
        margin: 0;
        height: 5vh;
        border: none;
        cursor: pointer;
        outline: none;
        background-color: white;
        transition: background-color 0.3s, color 0.3s;
  
        &:hover {
          border-bottom: .2vh solid #262626;
          color: #262626;
        }
  
        &.active {
          border-bottom: .2vh solid #262626;
          color: #262626;
        }
      }
    }
  
  }
  .video{
    width: 100%;
  }

  .noticetitle{
    width: 90%;
    padding: 4vh 0;
    font-family: 'Pretendard';
    .titleplace{
      font-size: 2.5vh;
      font-weight: bold;
      margin: 0;
    }
    .titletime{
      font-size: 1.7vh;
      color: #8D8D8D;
      margin: 1vh 0 0 0;
    }
  }
  .greygap{
    width: 100%;
    height: 3vh;
    background-color: #F2F4F8;
  }
  .otherplay{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Pretendard';
    .title{
      font-size: 2.2vh;
      font-weight: bold;
      margin-bottom: 1vh;
    }
  }
}