@import '../../../styles/variable';

.passwordBG{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .lostpw{
    margin-top: 3vh;
    margin-bottom: 20vh;
    color: #878D96;

    &:hover{
      color: black;
      transition: 1s all;
    }
  }
  .blank{
    margin: 28vh 0;
  }
}