@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

.myteam {
  @include display(flex, column, center, start);
  background-color: #eeeff3;
  width: 100%;
  max-width: 499px;
  .teamnav{
    @include display(flex, row, center, space-between);
    height: 10vh;
    width: 85%;
    .leftbtn{
      width: 3vh;
      height: 3vh;
    }
    .cogbtn{
      width: 3vh;
      height: 3vh;
    }
  }
  .logo {
    width: 85%;
  }
  .fc{
    width: 84%;
    margin: 2vh 0;
    font-size: 2.2vh;
    font-weight: 600;
    font-family: 'Pretendard';
  }
  .subjectbox{
    width: 90%;
    @include display(flex, row, center, center);
    gap: 1.5vh;
    margin: 2vh 0;
  }
  .teambox{
    background-color: white;
    width: 90%;
    min-height: 50vh;
    border-radius: 2vh;
    @include display(flex, column, center, start);
    margin-bottom: 5vh;
    .teamtitle{
      @include display(flex, row, center, space-between);
      width: 85%;
      border-bottom: 1px solid #E5E9ED;
      margin: 4vh 0 0 0;
      .managerbtn{
        color: #0EAC6A;
        font-size: 1.8vh;
        font-weight: 700;
        font-family: 'Pretendard';
      }
      & > p {
        font-size: 2.3vh;
        font-weight: 700;
        margin: 1vh 0;
        font-family: 'Pretendard';
      }
      & > img {
        height: 2vh;
      }
    }
    .detailbox{
      @include display(flex, row, center, space-between);
      width: 85%;
      .detail{
        @include display(flex, row, center, start);
        width: 50%;
        .t1{
          font-size: 1.7vh;
          font-weight: 500;
          margin: 1vh 0;
          color: #6F6F6F;
          font-family: 'Pretendard';
        }
        .t2 {
          font-size: 1.7vh;
          font-weight: 700;
          margin: 1vh 0;
          margin-left: .5vh;
          font-family: 'Pretendard';
        }
      }
    }
    
    .list{
      overflow-y: auto;
      @include display(flex, column, center, start);
      width: 100%;
      margin-top: 1vh;
    }
    .empty-message{
      font-size: 1.5vh;
      font-weight: 500;
      color:#878D96;
      margin-top: 10vh;
      text-align: center;
      font-family: 'Pretendard';
    }
  }
  .myteam-noneadvisor{
    background-color: white;
    width: 15vh;
    height: 10vh;
    border-radius: 1vh;
    box-shadow: 0 5px 5px rgb(228, 228, 228);
    position: absolute;
    top: 8vh;
    right: 7%;
    .exitteam{
      width: 13vh;
      height: 5vh;
      padding: 0 1vh;
      font-size: 1.5vh;
      display: flex;
      justify-content: start;
      font-family: 'Pretendard';
      align-items: center;
      border-bottom: 1px solid #E5E9ED;
    }
    .declair {
      width: 13vh;
      height: 5vh;
      padding: 0 1vh;
      font-size: 1.5vh;
      font-family: 'Pretendard';
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }
  .myteamMment{
    font-size: 2vh;
    font-weight: 600;
    font-family: 'Pretendard';
    margin-bottom: 4vh;

  }
  .myteam-buttonbox{
    width: 90%;
    display: flex;
    margin-bottom: -5vh;
  }
}