@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.teamsetting{
  @include display(flex, column, center, start);
  width: 100%;
  max-width: 499px;
  font-family: 'Pretendard';

  .setbox{
    width: 90%;
    border-bottom: 1px solid #E2E2E2;
    @include display(flex, row, center, space-between);
    padding: 1vh 0;
    .settitle{
      @include font(2vh, 600, #333333);
    }
    .arrow{
      rotate: 180deg;
      width: 3vh;
      height: 3vh;
    }
  }
  .exitteam{
    @include font(2vh, 600, #e20000);
    width: 90%;
    @include display(flex, row, center, space-between);
    padding: 3vh 0;
  }
}