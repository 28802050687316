@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.anal{
  @include display(flex, column, center, start);
  width: 100%;
  max-width: 499px;
  min-height: 100vh;
  background-color: rgb(242,244,246);
  .anal-box{
    display: flex;
    justify-content: space-between;
    width: 90%;
    .anal-title{
      @include font(3.2vh, 700, #262626);
      width: 90%;
      margin: 0 0 3vh 0;
    }
  }
  .line{
    width: 100%;
    height: 1.5px;
    background-color: #DADFE5;
    margin-bottom: 2vh;
  }
  .sort-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;

  }
  .total-play{
    width: 90%;
    @include display(flex, row, start, start);
    @include font(1.7vh, 600, #262626);
    font-family: 'Pretendard';
    & > p {
      margin: 0;
      @include font(1.8vh, 700, #262626);
    }
  }

  .anal-nocontents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60vh;
    img{
      width: 15%;
    }
    .anal-contenttitle{
      @include font(2.1vh, 600, black);
      font-family: 'Pretendard';
    }
    .anal-content{
      @include font(1.7vh, 600, #878D96);
      font-family: 'Pretendard';
    }
    .anal-btn{
      width: 50%;
      height: 5vh;
      background-color: #E5E9ED;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 1.8vh;
      border-radius: 3vh;
      font-family: 'Pretendard';
      margin-top: 3vh;
    }
  }

  .list {
    width: 100%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20vh;
    
  }
  .gap{
    height: 15vh;
  }
}