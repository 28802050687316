@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.personalvideo{
  width: 100%;
  max-width: 499px;
  @include display(flex, column, center, start);
  &:last-child {
    margin-bottom: 5vh;
  }

  .line {
    width: 90%;
    height: 1px;
    background-color: #DADFE5;
  }
  .sortvideo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    .totalnum{
      @include display(flex, row, center, start);
      width: 90%;
      margin: 2vh;
      font-family: 'Pretendard';
      @include font(1.8vh, 500, #262626);
      & > p{
        margin: 0;
        @include font(1.8vh, 700, #262626);
        font-family: 'Pretendard';
        margin-left: 1%;
      }
    }
  }
  
  .nomatchvideo{
    margin-top: 15vh;
    font-family: 'Pretendard';
    font-size: 2vh;
    font-weight: 500;
  }
}