@font-face {
  font-family: 'Paperlogy-8ExtraBold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-8ExtraBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body, html {
  /* safari 브라우저에서 pretendard 폰트 합성 문제 해결 */
  font-synthesis: none
}

body {
margin: 0;
font-family: 'Paperlogy-8ExtraBold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

/* Onboard 전용 스타일 */
body.onboard-body {
  max-width: 499px;
  max-height: 950px;
  margin: 0 auto;
  overflow: scroll;
  height: 100vh;
}

/* 기본 스타일 (ComponyIntroduce 등 다른 페이지) */
body.default-body {
  margin: 0;
  height: auto;
  overflow: auto;
}