.ppBG{
  position: fixed;
  display: flex;
  flex-direction: column;
  top:0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  overflow: scroll;
  align-items: center;
  justify-content: flex-start;
  .exitBtn{
    position: fixed;
    top: 2vh;
    right: 2vh;
  }
  .grid{
    margin-top: 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 4px;
    column-gap: 12px;
    max-width: 430px;
  }
}

// 원래 코드
/*
.ppBG{
  background-color: black;
  width: 100vw;
  height: 100vh;
  .exitBtn{
    width: 95vw;
    height: 100px;
    position: fixed;
    center: 0;
    display: flex;
    justify-content: right;
    align-items: center;
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: auto;             
    gap: 10px;                            
    padding: 15px;
    place-items: center;    
    & > div:last-child {                  
      grid-column: 2 / 3;                
      justify-self: center;
    }
  }
}
*/