@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

.userinfo {
  @include display(flex, column, center, center);
  min-height: 100vh;
  width: 100%;
  max-width: 499px;
  position: relative;

  .backdiv {
    position: fixed;
    width: 90%;
    top: 5vh;
    z-index: 1999;

    .backBtn {
      background-color: #262626;
      opacity: 50%;
      border: 1px solid #a8a8a8;
      width: 5vh;
      height: 5vh;
      border-radius: 50%;
      @include display(flex, column, center, center);
      .back {
        height: 2.5vh;
      }
    }
  }
  .cards-container {
    @include display(flex, column, center, flex-start);
    overflow-y: auto;
    height: 100vh;
    width: 100%;
    scroll-snap-type: y mandatory; 
    scroll-behavior: smooth;
    z-index: 2;
  
    .infoBack {
      @include display(flex, column, center, center);
      position: relative;
      height: 100vh; 
      width: 100%;
      scroll-snap-align: center;
      padding: 20px; 
      
    }
  }
}
