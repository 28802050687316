@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.selectreason{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 499px;

  .reasontitlebox{
    @include display(flex, row, center, space-between);
    width: 90%;
  
    .title1{
      @include font(2vh, 600, #333333);
      margin: 1.5vh 0;
      font-family: 'Pretendard';
    }
    .arrow1{
      width: 2.8vh;
      rotate: 180deg;
    }
  }
}