@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

.personalanal{
  @include display(flex, column,  center, start);
  width: 100%;
  max-width: 499px;
  min-height: 100vh; 
  &:last-child{
    margin-bottom: 10vh;
  }
  .greybackground{
    @include display(flex, column,  center, start);
    width: 100%;
    height: max-content;
  }
}