@import '../../../styles/variable';

.EmailBG{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .gap2{
    margin-top: 15vh;
  }
}