@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.maketeam{
  @include display(flex, column, center, center);
  width: 100%;
  max-width: 499px;
  .error-message{
    @include font(1.5vh, 500, red);
    width: 90%;
    margin-top: 2vh;
  }
  .circletab{
    width: 100%;
    position: absolute;
    bottom: 8vh;
  }
}