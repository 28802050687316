.modal-overlayl {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 1000;
  .modal-contentl {
    background: white;
    border-radius: 3vh;
    width: 90%;
    height: 35vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 10vh;
    .modal-close {
      position: absolute;
      top: 56vh;
      right: 4vh;
      background: none;
      border: none;
      font-size: 4vh;
      cursor: pointer;
      color: #6F6F6F;
    }  
  }
}



