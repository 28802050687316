.eiBG{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 499px;
  .genderBox{
    display: flex;
    width: 85vw;
    justify-content: space-between;
    align-items: center;
    margin: 5vh 0 0 0;
  }
}