@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

.recentmatch{
  @include display(flex, column, center, center);
  background-color: white;
  width: 90%;
  height: 20vh;
  margin-bottom: 15vh;
  border-radius: 2vh;
  .recentmatch_arrowtitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    .title{
      font-size: 2vh;
      font-weight: 700;
    }
    & > img {
      width: 2.4vh;
      height: 2.4vh;
      rotate: 180deg;
    }
  }
  .detailbox{
    @include display(flex, row, end, space-between);
    width: 90%;
    .detail{
      @include display(flex, column, space-between, start);
      width: 100%;
      height: 100%;
      
    
      .place{
        @include display(flex, row, start, start);
        width: 90%;
        font-size: 1.3vh;
        font-weight: 500;
        color: #6F6F6F;
        font-family: 'Pretendard';
        
        & > img{
          height: 1.7vh;
        }
      }
      .fc{
        font-size: 1.7vh;
        font-weight: 600;
        margin: 1vh 0 2vh 0;
        font-family: 'Pretendard';
      }
      .date{
        font-size: 1.3vh;
        font-weight: 500;
        color: #A8A8A8;
        font-family: 'Pretendard';
      }
    }
    .logo{
      @include display(flex, row, start, start);
      height: 42%;
      width: 32%;
      position: relative;
      bottom: 2vh;
      right: -1vh;
      .imgbox{
        width: 6vh;
        height: 6vh;
        overflow: hidden;
        
        .img{
          object-fit: cover;
          width: 6vh;
        }
      }
      .imgbox2{
        width: 6vh;
        height: 6vh;
        overflow: hidden;
        position: relative;
        right: 1.2vh;
        .img{
          object-fit: cover;
          width: 6vh;
        }
      }
    }
  }
}