@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.membermanage{
  @include display(flex, column, center, center);
  width: 100%;
  max-width: 499px;
  font-family: 'Pretendard';
  .managecontents{
    width: 100%;
    @include display(flex, column, center, start);
    .detail{
      @include display(flex, row, center, start);
      width: 90%;
      .t1{
        font-size: 1.7vh;
        font-weight: 500;
        margin: 1vh 0;
        color: #6F6F6F;
      }
      .t2 {
        font-size: 1.9vh;
        font-weight: 700;
        margin: 1vh 0;
        margin-left: .5vh;
      }
      .t3{
        font-size: 1.7vh;
        font-weight: 500;
        margin: 1vh 0;
        color: #6F6F6F;
      }
    }
    .list{
      overflow-y: scroll;
      @include display(flex, column, center, start);
      width: 100%;
      height: 70vh;
      
    }
    .empty-message{
      font-size: 2vh;
      font-weight: 500;
      color:#878D96;
      margin-top: 10vh;
      text-align: center;
    }
  }
}