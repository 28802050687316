.extraBG{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 499px;
  .prefpo{
    background-color: #F2F4F8;
    width: 90%;
    height: 55px;
    display: flex;
    align-items: center;
    border-radius: 0 0 15px 15px;
    margin: 0 0 40vh 0;

    & > div{
      font-size: 17px;
      font-weight: 600;
      font-family: 'Pretendard';
      color: #000000;
      width: 80%;
      height: 55px;
      font-size: 16px;
      padding: 0 4vw 0 4vw;
      display: flex;
      align-items: center;
    }
    & > img{
      height: 20px;
      transform: rotate(180);
    }
  }
  .modal-inner{
    width: 90%;
    .symbol{
      width: 7vh;
      margin-top: 3vh;
    }
    .modal-title{
      font-size: 2.2vh;
      font-weight: 600;
    }
  }
  
}