@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color
}

@mixin display($dir, $jus, $align){
  display: flex;
  flex-direction: $dir;
  justify-content: $jus;
  align-items: $align;
}

.mainsummary{
  @include display(column, center, center);
  margin-top: 5vh;
  .infobox{
    width: 90%;
    @include display(row, space-between, center);
    .info{
      @include display(column, center, start);
      .age{
        @include font(1.8vh, 500, #878D96);
        margin: 1vh 0;
        font-family: 'Pretendard';
      }
      .name{
        @include font(5vh, 400, #121619);
        margin: 5px 0;
        font-family: 'Pretendard';
      }
    }
    .position{
      width: 80px;
      height: 80px;
      @include display(row, center, center);
      @include font(2vh, 700, white);
      font-family: 'Pretendard';
      .ellipse{
        width: 100%;
        height: 100%;
      }
      .position-tag{
        position: absolute;
        margin: auto;
      }
    }
  }
  .playbox{
    @include display(column, center, start);
    width: 90%;
    .datetitle{
      @include font(1.7vh, 500, #9ca2a8);
      margin: 1vh 0 0 0;
      font-family: 'Pretendard';
    }
    .bottombox{
      width: 100%;
      @include display(row, space-between, center);
      .date{
        @include font(1.5vh, 700, #697077);
        font-family: 'Pretendard';
        margin: .5vh 0 0 0;
      }
      .detail{
        @include font(1.6vh, 700, #A2A9B0);
        font-family: 'Pretendard';
        margin: 0;
      }
    }
  }
}