.completeBG{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 499px;
  .IllCheck{
    width: 130px;
    margin: 5vh 0 10vh 0;
  }
  .blank{
    margin: 5px;
  }
  .divider {
    display: flex;
    align-items: center;
    color: #A0A0A0;
    font-size: 13px;
    margin: 3vh 0;
    width: 90%;
    font-family: 'Pretendard';
    font-weight: 500;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #D3D3D3;
      margin: 0 10px;
      height: 1px;
    }
  }
}