@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

.userinfo {
  @include display(flex, column, center, center);
  min-height: 100vh;
  width: 100%;
  max-width: 499px;

  .backdiv {
    width: 90%;
    top: 5vh;

    .backBtn {
      background-color: #262626;
      opacity: 50%;
      border: 1px solid #a8a8a8;
      font-family: 'Pretendard';
      width: 5vh;
      height: 5vh;
      border-radius: 50%;
      @include display(flex, column, center, center);

      .back {
        height: 2.5vh;
      }
    }
  }
}
