@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
.changeteamname{
  @include display(flex, column, center, center);
  width: 100%;
  max-width: 499px;
  .gap{
    height: 55vh;
  }
}