@mixin display($dis, $dir, $ali, $jus) {
  display: $dis;
  flex-direction: $dir;
  align-items: $ali;
  justify-content: $jus;
}

@mixin font($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.videoback{
  @include display(flex, column, center, start);
  width: 100%;
  max-width: 499px;
  background-color: rgb(244,245,248);
  height: 100vh;
  .videotitle{
    width: 90%;
    @include font(3vh, 700, #262626);
  }
  .contents{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vh;
    margin-top: 6vh;
    .contentbox{
      @include display(flex, column, start, center);
      width: 100%;
      height: 20vh;
      .thumbnail-stack {
        position: relative;
        width: 80%;
        height: 14vh;
      
        .thumbnail-layer {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background-color: #d9d9d9; 
          background-size: cover;
          background-position: center;
          transition: all 0.3s ease;
      
          &:nth-child(1) {
            z-index: 3;
            left: 0;
          }
      
          &:nth-child(2) {
            z-index: 2;
            left: 12px;
          }
      
          &:nth-child(3) {
            z-index: 1;
            left: 24px;
          }
      
          &.image {
            background-color: transparent;
          }
        }
      }
      
      .content4{
        width: 95%;
        height: 14vh;
        background-color: #4D5358;
        border-radius: 1vh;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 3vh;
        }
      }
      .contenttitle{
        @include font(2vh, 600, #262626);
        margin: 1.5vh 0 1vh 0;
        font-family: 'Pretendard';
      }
      .contentnumber{
        @include font(1.5vh, 600, #8D8D8D);
        margin: 0 0 1vh 0;
        font-family: 'Pretendard';
      }
    }
  }}