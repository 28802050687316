@import '../../../styles/variable';

.NotUserBG{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .NoUserImg{
    width: 180px;
    margin: 10vh 0 30vh 0;
  }
  .GoBack{
    color: #878D96;
    text-decoration: underline;
  }
}